<script>
import { wpService } from "@/services/wp";
export default {
  data() {
    return {
      data: null,
    };
  },

  methods: {
    confirmReject() {
      this.$buefy.dialog.confirm({
        title: "Confirmar",
        message:
          "Are you sure you want to <b>reject</b> this gift? This action cannot be undone.",
        confirmText: "Rechazar regalo",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.reject(),
      });
    },
    async reject() {
      const loading = this.$buefy.loading.open();
      await wpService.gifts
        .reject(this.gift.gift_id)
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch(err => {
          this.manageError(err.code);
        });
      loading.close();
    },
    async accept() {
      const loading = this.$buefy.loading.open();
      await wpService.gifts
        .accept(this.gift.gift_id)
        .then(() => {
          this.$router.push({ name: "home" });
        })
        .catch(err => {
          this.manageError(err.code);
        });
      loading.close();
    },
    manageError(errorCode = "") {
      let message = "Enlace inválido.";
      switch (errorCode) {
        case "not_recipient":
          message = "Oops! Este regalo no te pertenece.";
          break;
        case "has_org":
          message = "Ya perteneces a una organización.";
          break;
      }
      this.$buefy.dialog.alert({
        onConfirm: () => {
          this.$router.push({ name: "home" });
        },
        message,
      });
    },
  },

  created() {
    const loading = this.$buefy.loading.open();
    const invite_id = Number(this.$route.params.invite_id) || 0;
    if (invite_id < 1) {
      return this.$router.replace({ name: "home" });
    }
    wpService.organizations
      .orgInviteVerify(invite_id)
      .then(data => {
        this.data = data;
      })
      .catch(err => {
        console.log("error", err);
        this.manageError(err.code);
      })
      .finally(() => {
        loading.close();
      });
  },
};
</script>

<template>
  <div class="layout">
    <!-- <b-loading v-if="!data" :active="true" /> -->
    <div v-if="!data"></div>
    <div v-else class="invite content">
      <div class="host_avatar">
        <img :src="data.host.user_avatar.src" alt="" />
      </div>
      <p>
        <router-link
          :to="{ name: 'user', params: { user_id: data.host.user_id } }"
          ><b>{{ data.host.user_name_full }}</b></router-link
        >
        te ha invitado a unirte a la organización
        <router-link
          :to="{
            name: 'organization-single',
            params: { org_slug: data.organization.org_slug },
          }"
          ><b>{{ data.organization.org_name }}</b></router-link
        >
      </p>
      <div class="buttons">
        <b-button type="is-link">Aceptar</b-button>
        <b-button>Rechazar</b-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.layout {
  text-align: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}
.host_avatar {
  max-width: 7rem;
  border-radius: 9999px;
  overflow: hidden;
  margin: 0 auto 2rem;
  img {
    display: block;
  }
}
.buttons {
  margin-top: 1.5rem;
  justify-content: center;
}
</style>
